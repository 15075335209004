import { Col, Typography } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import FooterDTL from "../../layout/footer";
import Header from "../../layout/header";
import { axiosErrorHandler } from "../../utilities";
import { LeaderboardView } from "../common/components";
import "../common/styles.css";
import { Leaderboard } from "./models";
import { privateLeaderboard, publicLeaderboard } from "./resources";

const PublicLeaderboard: React.FC = () => {
  const [tableData, setTableData] = useState<Leaderboard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useAuth();
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await publicLeaderboard.list({});
        setTableData(data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        const err = error as AxiosError;
        axiosErrorHandler(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header />
      <Content>
        <Col span={16} offset={4}>
          <Typography.Title level={3} style={{ margin: "1rem" }}>
            {/* TODO: Top X - the value X should be sent from backend */}
            Public Leaderboard (Top 5)
          </Typography.Title>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <LeaderboardView data={tableData} />
          )}
        </Col>
      </Content>
      <FooterDTL />
    </Layout>
  );
};

const PrivateLeaderboard: React.FC = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const gracePeriodEndDate = auth.contestGracePeriodEndDate;
  const hasGracePeriodEnded: boolean = new Date() > gracePeriodEndDate;
  const hasEvaluationResults = auth.evaluationState === "done";
  const showResults = auth.showPrivateLeaderboard && hasEvaluationResults;

  const [tableData, setTableData] = useState<Leaderboard[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!hasGracePeriodEnded) {
      navigate("/quant/public_leaderboard", { replace: true });
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const { data } = await privateLeaderboard.list({});
        setTableData(data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
        const err = error as AxiosError;
        axiosErrorHandler(err);
      } finally {
        setLoading(false);
      }
    })();
  }, [hasGracePeriodEnded, navigate]);

  return (
    <Layout style={{ height: "100vh" }}>
      <Header />
      <Content>
        <Col span={16} offset={4}>
          <Typography.Title level={3} style={{ margin: "1rem" }}>
            {/* TODO: Top X - the value X should be sent from backend */}
            Private Leaderboard (Top 10)
          </Typography.Title>
          {!showResults ? (
            <div style={{ margin: "1rem" }}>
              We are currently running the final evaluations, hang tight! Please
              refresh the page at a later time.
            </div>
          ) : loading ? (
            <div>Loading...</div>
          ) : (
            <LeaderboardView data={tableData} />
          )}
        </Col>
      </Content>
      <FooterDTL />
    </Layout>
  );
};

export { PrivateLeaderboard, PublicLeaderboard };
