import React, { useEffect } from "react";

import { registerRestful, registrationDetailsRestful } from "./resources";

import { IRegistrationData } from "./models";

import { axiosErrorHandler } from "../utilities";

import { AxiosError } from "axios";

const SGT_TIMEZONE_OFFSET = "+08:00";

interface RegisterContextType {
  register: (
    registrationData: IRegistrationData,
    beforeSignIn: VoidFunction,
    callbackSuccess: VoidFunction,
    callbackFailure: VoidFunction,
  ) => Promise<void>;
  registrationStartDate: Date;
  registrationEndDate: Date;
  contest?: any;
}

const defaultRegisterContext: RegisterContextType = {
  register: async () => {
    console.log("dummy registration");
  },
  registrationStartDate: new Date(),
  registrationEndDate: new Date(),
  contest: {},
};

const RegisterContext = React.createContext<RegisterContextType>(
  defaultRegisterContext,
);

export function RegisterProvider({ children }: { children: React.ReactNode }) {
  const [registrationStartDate, setRegistrationStartDate] =
    React.useState<Date>(new Date()); // if the registration start date is not fetchable, then the start date will be now
  const [registrationEndDate, setRegistrationEndDate] = React.useState<Date>(
    new Date(Date.now() + 1000 * 60 * 60 * 24 * 2),
  ); // if the registration end date is not fetchable, then the end date will always be current date + 2 days, which means the registration never ends
  // these defaults will allow the user to fill in the form and submit it
  // but, the backend will stil validate the actual registration windwo

  const [contest, setContest] = React.useState<Object>({});

  useEffect(() => {
    const getContestDetails = async () => {
      try {
        const res = await registrationDetailsRestful.retrieve({
          pk: "registration_end_date",
        });
        setContest(res.data.contest);
        setRegistrationStartDate(
          new Date(res.data.registration_start_date + SGT_TIMEZONE_OFFSET),
        );
        setRegistrationEndDate(
          new Date(res.data.registration_end_date + SGT_TIMEZONE_OFFSET),
        );
      } catch (e) {
        const err = e as AxiosError;
        axiosErrorHandler(err);
      }
    };

    getContestDetails();
  }, []);

  const register = async (
    registrationData: IRegistrationData,
    beforeSignIn: VoidFunction,
    callbackSuccess: VoidFunction,
    callbackFailure: VoidFunction,
  ) => {
    beforeSignIn();
    let success = false;
    try {
      await registerRestful.create({ data: registrationData });
      success = true;
    } catch (e) {
      const err = e as AxiosError;
      axiosErrorHandler(err);
      success = false;
    } finally {
      if (success) {
        callbackSuccess();
      } else {
        callbackFailure();
      }
    }
  };

  const value = {
    register,
    registrationStartDate,
    registrationEndDate,
    contest,
  };

  return (
    <RegisterContext.Provider value={value}>
      {children}
    </RegisterContext.Provider>
  );
}

export function useRegister() {
  return React.useContext(RegisterContext);
}
